import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import Awesome from "~components/awesome"
import DRY from "~components/dry"

import FrontmatterPageContext from "~context/FakePageContext"

import { mainArea, main } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import LayoutCore from "./layout-core"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

import { LoremIpsum } from "react-lorem-ipsum"

const LayoutDefault = ({ pageContext }) => {
  const { frontmatter, body } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto, Awesome, DRY, CodeBlock, LoremIpsum, CodeBlock, pre: CodeBlock }

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <div
            className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
            role="navigation"
            aria-label="Navigation between documents"
            data-swiftype-index="false"
          >
            <SideMenuSwitcher />
          </div>

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-content">
              <MDXProvider components={mdx_components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>

              {/*
          {% if page.section == 'fundamentals' %}
          {% assign my_primary = site.en_fundamentals_primary | where: "cat_id", page.primary-nav | first %}
          {% if my_primary['navigation'] == true %}
          {% include partial/back_next_buttons.html plang=page.lang %}
          {% endif %}
          {% endif %}
          */}

              {/* {% include partial/form_feedback.html id="7a402cfe248d" %} */}
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutDefault

export const Head = (props) => {
  return (
    <>
      <title>{`${props.pageContext.frontmatter.title ?? "Fastly Docs"}`}</title>
      <SeoHead {...props} />
      <FavHead />
    </>
  )
}
